/*
 -  Container body
 -
 -
 */

.container-fluid {
  // Media queries
  @media (min-width: ( $body-max-width )) {
    max-width: $body-max-width;
  }
}

.body-inner {
  &--overlay {
    @include overlayer;
  }

  &--overlay-header .header,
  &--overlay-aside .aside {
    @include overlayer(auto, absolute, after);
  }

  &--overlay-full {
    height: auto;

    &::before {
      z-index: $zindex-body-overlayer-full;
    }
  }
}

.content-box {
  position: relative;
  padding-top: ($spacer * 6);
  padding-bottom: ($spacer * 4);
  background-color: $white;
  border-bottom: 4px solid $green-200;

  &.wide {
    padding-left: ($spacer * 4);
    padding-right: ($spacer * 4);
  }
}

/*
.overlay--visible {
	z-index: $zindex-body-overlayer-visible;

	&.dropdown {
		.dropdown-link__text,
		.dropdown-toggle-icon,
		.btn__icon {
			color: $white;
		}

		.dropdown-toggle.dropdown-link {
			&:hover {
				.dropdown-link__text {
					border-bottom-color: $white;
				}
			}
		}
	}
}
*/
