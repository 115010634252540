// Global margin bottom indentation
@mixin mb-global-indentation(
  $val-tablet: $mb-global-indentation-tablet,
  $val: $mb-global-indentation
) {
  margin-bottom: $val-tablet;

  // Media queries
  @include media-breakpoint-up(lg) {
    margin-bottom: $val;
  }
}
