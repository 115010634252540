/*
 -  Container content
 -
 -
 */

.container-content {
  padding: 0 0 map-get($spacers, 6) 0;

  &.bg-gradient {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    &--var-1 {
      &::before {
        @include turquoise-dark-gradient;
        height: 46.67rem;
      }
    }
  }

  &--without-stepper {
    &.bg-gradient {
      &::before {
        top: 0;
      }
    }
  }

  &--wide {
    .container-fluid {
      @media (min-width: ( $body-max-width )) {
        max-width: none;
      }
    }
  }
}

.container-content-public {
  height: 100%;
  padding: ($spacer * 20) 0 ($spacer * 4);

  &.bg-gradient {
    &::before {
      top: 0;
      height: 100vh;
    }
  }
}
