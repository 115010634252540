/*
 -	Theme utilities
 */

// Set text size to 12px, (1rem = 18px / ref to desktop)
.font-size-12 {
  font-size: 0.667rem;
}

// Set text size to 13px, (1rem = 18px / ref to desktop)
.font-size-13 {
  font-size: 0.722rem;
}

// Set text size to 14px, (1rem = 18px / ref to desktop)
.font-size-14 {
  font-size: 0.778rem;
}

// Def link
.link {
  @include link-external;

  @include link-without-href {
    cursor: pointer;
    display: inline-block;
    color: $link-color;
    text-decoration: $link-decoration;
  }

  @include link-without-href-interactive {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &--external {
    @include link-external-icon;
  }

  &--disabled {
    @include link-without-href-self {
      @include disabled-state;
    }
  }

  &--icon {
    @include link-without-href {
      text-decoration: none;
    }

    @include link-without-href-interactive {
      color: $green-200;
    }
  }
}

.icon--interactive {
  @include link-without-href {
    text-decoration: none;
  }

  @include link-without-href-interactive {
    color: $green-200;
  }
}

@each $size, $length in $icon-sizes {
  .icon--#{$size} {
    height: $length;
  }
}

.cursor-pointer,
.interaction--click {
  cursor: pointer;
}

.interaction--move {
  cursor: move;
}

/*
 *  Colors utilities via properties generator
 */

$colors: (
  'green-100': $green-100,
  'green-200': $green-200,
  'green-300': $green-300,
  'green-400': $green-400,
  'blue': $blue,
  'sky-gray': $sky-gray,
  'titanium-gray': $titanium-gray,
  'night-blue': $night-blue,
  'light-blue': $light-blue,
  'gray-000': $gray-000,
  'gray-100': $gray-100,
  'gray-150': $gray-150,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'gray-1000': $gray-1000,
  'success-lighten': $success-lighten,
  'success': $success,
  'success-dark': $success-dark,
  'success-darken': $success-darken,
  'warning-lighten': $warning-lighten,
  'warning': $warning,
  'warning-dark': $warning-dark,
  'warning-darken': $warning-darken,
  'danger-lighten': $danger-lighten,
  'danger': $danger,
  'danger-dark': $danger-dark,
  'danger-darken': $danger-darken,
  'promo-cheese': $promo-cheese,
  'promo-salmon': $promo-salmon,
  'promo-tomato': $promo-tomato,
  'promo-avocado': $promo-avocado,
  'promo-champagne': $promo-champagne,
  'white': $white,
  'black': $black,
  'primary': $primary,
  'primary-dark': $primary-dark,
  'secondary': $secondary,
  'secondary-dark': $secondary-dark,
  'light': $light,
  'light-dark': $light-dark,
  'light-darken': $light-darken,
  'disabled': $disabled,
  'icons-primary': $icons-primary,
  'icons-active': $icons-active,
  'icons-inactive': $icons-inactive,
  'gradient-blue-light': $gradient-blue-light,
  'gradient-blue-dark': $gradient-blue-dark,
  'gradient-green-light': $gradient-green-light,
  'gradient-green-dark': $gradient-green-dark,
  'gradient-orange-light': $gradient-orange-light,
  'gradient-orange-dark': $gradient-orange-dark,
  'gradient-red-light': $gradient-red-light,
  'gradient-red-dark': $gradient-red-dark
);

@each $name, $value in $colors {
  .bg--#{$name} {
    background-color: $value;
  }

  .text-color--#{$name} {
    color: $value;
  }
}

@include generate-gradient('blue', $gradient-blue-light, $gradient-blue-dark);
@include generate-gradient('green', $gradient-green-light, $gradient-green-dark);
@include generate-gradient('orange', $gradient-orange-light, $gradient-orange-dark);
@include generate-gradient('red', $gradient-red-light, $gradient-red-dark);

/* Generate progress bars */
@include generate-progress; // width
@include generate-progress(height, progress-h); // height

// Font sizes
.font-size-normal {
  font-size: $font-size-base;
}

.font-size-smaller {
  font-size: $smaller-font-size;
}

.font-size-small {
  font-size: $small-font-size;
}

// Font weights
.font-weight-medium {
  /* stylelint-disable declaration-no-important */
  font-weight: $font-weight-medium !important;
  /* stylelint-enable */
}
.font-weight-semibold {
  /* stylelint-disable declaration-no-important */
  font-weight: $font-weight-semibold !important;
  /* stylelint-enable */
}
.font-weight-bold {
  /* stylelint-disable declaration-no-important */
  font-weight: $font-weight-bold !important;
  /* stylelint-enable */
}

.bg--turquoise-dark-gradient {
  @include turquoise-dark-gradient;
}

.bg--turquoise-light-gradient {
  @include turquoise-light-gradient;
}

.bg--emerald-gradient {
  @include emerald-gradient;
}

// Application global right side offset
.l-offset {
  @include l-offset;
}

// Application global left side offset
.r-offset {
  @include r-offset;
}

// Application global margin bottom indentation
.mb-global-indentation {
  @include mb-global-indentation;
}

.text-nowrap {
  white-space: nowrap;
}

.text-breakword {
  word-wrap: break-word;
}

.text-center {
  text-align: center;
}

.text-error {
  color: $red;
}

.text-with-icon {
  display: flex;
  margin-bottom: ($spacer * 1.75);

  &__icon {
    padding-top: ($spacer * 0.75);
    padding-right: ($spacer * 2);
  }
}

$preloader-size: 18rem * 0.05;

.lds-dual-ring {
  display: inline-block;

  &:after {
    content: ' ';
    display: block;
    width: $preloader-size;
    height: $preloader-size;
    margin: 0.17rem;
    border-radius: 50%;
    border: 2px solid $green-200;
    border-color: $green-200 transparent $green-200 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bs-tooltip-top {
  top: -7px !important;
}

.tooltip {
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.2));
}

.tooltip-inner {
  border-radius: $tooltip-border-radius;
  padding: 0.4rem 0.75rem;
}
