.actions {
  .kpt-dropdown-bootstrap__dropdown-menu {
    min-width: 14rem;
    white-space: nowrap;
  }
}

.no-bottom-margin {
  .form-group {
    margin-bottom: 0;
  }
}

.space-below {
  .row {
    margin-bottom: 0.56rem;
  }
}
