/*
 -  Front
 -
 -  This file includes and describes all used CSS written in SCSS preprocesor.
 */

/* -------------------------- */
/*   1. CORE - CONFIG,        */
/*             BASE           */
/*             VENDORS        */
/* -------------------------- */

@import './01_base/core'; // Core CSS

/* ------------------------- */
/*   2. LAYOUT               */
/* ------------------------- */

@import './02_layout/body'; // Container body
@import './02_layout/container-content'; // Container content
@import './02_layout/main'; // Main
@import './02_layout/static-pages'; // Static pages
@import './02_layout/table'; // Table

/* ------------------------- */
/*   3. COMPONENTS           */
/* ------------------------- */

// Seperate and sort partials into blocks with different concerns if there's too much partials

@import './03_components/control'; // Control
@import './03_components/input'; // Input
@import './03_components/data-table'; // Data table

/* ------------------------- */
/*   4. MODULES              */
/* ------------------------- */

@import './04_modules/financial-plan'; // Financial plan
@import './04_modules/financial-analysis'; // Financial analysis

/* ------------------------- */
/*   5. TEMPLATES & PAGES    */
/* ------------------------- */

@import './05_pages/about-consultant'; // About consultant

/* ------------------------- */
/*   5. LIBRARIES            */
/* ------------------------- */

@import './06_libraries/intro-js'; // Theme for intro.js library
