/*
 -	Theme mixins
 -
 -	This file defines new mixins and extends standards mixins from Bootstrap.
 */

/*
 -	Font-size mixin
 -
 -	Mixin for rem font sizing with fallback, use it with only if you need browser support < IE11
 -	@param {Number} [size-value] - Size of text
 */

// @mixin font-size($size-value: 16) {
// 	font-size: $size-value + px;
// 	font-size: ($size-value / 10) + rem;
// }

/*
 -  Disabled outline
 */

@use 'sass:math';

@mixin clear-outline() {
  outline: none;
  outline: 0;
}

/*
 -  Placeholder
 -
 -  Change color of font in placeholder for input
 -  @param {HEX Color} [color]
 */

@mixin placeholder($color) {
  /* WebKit browsers */
  &::-webkit-input-placeholder {
    color: $color;
  }

  /* Mozilla Firefox 4 to 18 */
  &:-moz-placeholder {
    color: $color;
  }

  /* Mozilla Firefox 19+ */
  &::-moz-placeholder {
    color: $color;
  }

  /* Internet Explorer 10+ */
  &:-ms-input-placeholder {
    color: $color;
  }
}

/*
 -  Icons
 -
 */

@mixin icon() {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword, declaration-no-important */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: $icon-size;
}

/*
 -  Selection Style
 -
 -  Change appearence of highlighted text
 -  @param {HEX Color} [highlight-color]
 -  @param {HEX Color} [font-color]
 */

@mixin selection($highlight-color: $black, $font-color: $white) {
  ::selection {
    background-color: $highlight-color;
    color: $font-color;
  }

  ::-moz-selection {
    background-color: $highlight-color;
    color: $font-color;
  }
}

/*
 -	Reset parameters padding and margin
 -	@param {Number} [value=0] - Set padding and margin to all sides
 */

@mixin reset-pa-ma($value: 0) {
  padding: $value;
  margin: $value;
}

/*
 -	Re-set default list options
 -	@param {String} [style=none] - Set list style type
 -	@param {Number} [padding=0] - Set padding
 -	@param {Number} [margin=0] - Set margin
 */

@mixin reset-list($style: none, $padding: 0, $margin: 0) {
  list-style: $style;
  padding: $padding;
  margin: $margin;
}

/*
 -	Micro-clearfix method
 */

@mixin after-clear() {
  &::after {
    clear: both;
    content: '';
    display: block;
    height: 0;
  }
}

/*
 -	Font-face declaration for custom typography
 -	@param {String} [font-name] - Name of the font family
 -	@param {url} [file-name] - URL address for font files
 -	@param {Number} [weight=400] - Weight or boldness of the font
 -	@param {String} [style=normal] - Face of the font-family (normal, italic or oblique)
 */

@mixin font-face($font-name, $file-name, $weight: 400, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    // src: url($file-name + '.eot');
    src: url('../' + $file-name + '.ttf') format('truetype');
    // url($file-name + '.woff') format('woff'),
    // url($file-name + '.eot?#iefix')  format('embedded-opentype'),
    // url($file-name + '.svg##{$font-name}')  format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

/*
 -	Absolute positioning of element to the center
 -	@param {Boolean} [vertical=true] - Vertical positioning of the element
 -	@param {Boolean} [horizontal=true] - Horizontal positioning of the element
 */

@mixin absolute-center($vertical: true, $horizontal: true) {
  position: absolute;
  display: block;

  @if $vertical == true and $horizontal == false {
    top: 50%;
    transform: translateY(-50%);
  } @else if $horizontal == true and $vertical == false {
    left: 50%;
    transform: translateX(-50%);
  } @else if $horizontal == true and $vertical == true {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/*
 -	Center positioning of the block content
 -	@param {String} [mode=row] - Direction of block elements in flex box (row or column)
 -	@param {Boolean} [vertical=true] - Vertical positioning of the element
 -	@param {Boolean} [horizontal=true] - Horizontal positioning of the element
 */

// @mixin center-content($mode: row, $horizontal: true, $vertical: true) {
// 	display: flex;
// 	flex-direction: $mode;

// 	@if $mode == row {
// 		@if $vertical == true and $horizontal == false {
// 			align-items: center;
// 		} @else if $horizontal == true and $vertical == false {
// 			justify-content: center;
// 		} @else if $horizontal == true and $vertical == true {
// 			justify-content: center;
// 			align-items: center;
// 		}
// 	} @else if $mode == column {
// 		@if $vertical == true and $horizontal == false {
// 			justify-content: center;
// 		} @else if $horizontal == true and $vertical == false {
// 			align-items: center;
// 		} @else if $horizontal == true and $vertical == true {
// 			justify-content: center;
// 			align-items: center;
// 		}
// 	}
// }

@mixin button-ghost-color($color, $color-second: $color) {
  @include btn-active {
    color: $color;
  }

  @include btn-interactive {
    color: $color-second;
    border-color: $color-second;
  }
}

/*
 -	Reset parameters padding and margin
 -	@param {Number} [value=0] - Set padding and margin to all sides
 */

@mixin overlayer($height: 100vh, $position: fixed, $subElement: before) {
  position: relative;
  height: $height;

  @if $position == fixed {
    overflow: hidden;
  }

  &::#{$subElement} {
    content: '';
    position: $position;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($sky-gray, 0.5);
    z-index: $zindex-body-overlayer;
  }
}

/*
 - Mixin for link without [href]
 */

@mixin link-without-href() {
  &:not([href]):not([tabindex]),
  &:not([href]):not([tabindex]):visited {
    @content;
  }
}

@mixin link-without-href-self() {
  & {
    @content;
  }

  @include link-without-href {
    @content;
  }
}

@mixin link-without-href-interactive() {
  &:not([href]):not([tabindex]):not(.active).focus,
  &:not([href]):not([tabindex]):not(.active):focus,
  &:not([href]):not([tabindex]):not(.active):hover {
    @content;
  }
}

@mixin link-without-href-interactive-self() {
  &:hover,
  &:focus {
    @content;
  }

  @include link-without-href-interactive {
    @content;
  }
}

/*
 - Mixin for link external
 */

@mixin link-external() {
  &[href^='http://'],
	&[href^='https://'],
	&[href^='//']
  {
    @include link-external-icon;
  }
}

@mixin link-external-icon() {
  &::after {
    display: inline-block;
    padding-left: ($spacer * 1.25);
    @include icon;
    content: '\e90b';
    font-size: $link-external-icon-size;
    text-decoration: none;
  }
}

@mixin btn-interactive() {
  &:focus,
  &.focus,
  &:hover,
  &:active {
    @content;
  }
}

@mixin btn-interactive-self() {
  & {
    @content;
  }

  @include btn-interactive {
    @content;
  }
}

@mixin btn-active() {
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @content;
  }
}

@mixin btn-disabled() {
  &.disabled,
  &.btn-disabled,
  &[disabled],
  fieldset[disabled] & {
    @content;
  }
}

@mixin disabled-state() {
  color: $disabled;
  cursor: default;
  pointer-events: none;
}

/*
 *	Gradients mixins
 */

@mixin generate-gradient($name-color, $start-color, $end-color) {
  .bg--gradient-#{$name-color} {
    background: linear-gradient(90deg, $start-color 10%, $end-color 90%);
  }
}

/*
 *	Progress bar classes for width in % units
 */

@mixin generate-progress($dimension: width, $class: progress) {
  $i: 0;
  $n: 100;

  @while $i <= $n {
    .#{$class}--#{$i} {
      #{$dimension}: math.div($i * 100%, $n);
    }

    $i: $i + 1;
  }
}

// Turquoise dark gradient
@mixin turquoise-dark-gradient {
  background: linear-gradient(0deg, $green-200 0%, $green-300 100%);
}

// Turquoise light gradient
@mixin turquoise-light-gradient {
  background: linear-gradient(0deg, $green-100 0%, $green-200 100%);
}

// Emerald gradient
@mixin emerald-gradient {
  background: linear-gradient(0deg, $sky-gray 0%, $green-300 100%);
}

// Global left side offset
@mixin l-offset {
  padding-left: 1.11rem;
}

// Global right side offset
@mixin r-offset {
  padding-right: 1.11rem;
}

// Calculate the grid width
@mixin grid-column-width($val: 10, $grid-columns: $grid-columns) {
  width: calc(((100% + #{$spacer}) / #{$grid-columns}) * #{$val});
  padding: 0 ($spacer * 0.5);
}

@mixin loadingElement($animationDuration: 1000ms) {
  position: relative;
  overflow: hidden;
  pointer-events: none;

  color: $disabled;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    width: 80%;

    animation: loadingIndicator $animationDuration infinite linear;

    background: $primary-dark;
    opacity: 0.3;
  }
}

@mixin loadingButton($animationDuration: 1000ms) {
  @include loadingElement($animationDuration);

  background: $btn-disabled-bg;
  color: $btn-disabled-color;

  &:focus {
    background: $btn-disabled-bg;
  }
}

@keyframes loadingIndicator {
  from {
    transform: translate3d(-150%, 0, 0);
  }

  to {
    transform: translate3d(150%, 0, 0);
  }
}
