@import 'src/assets/scss/01_base/base';

$tile-margin: $spacer-5 * 0.5;
$data-table-border: 2px solid $light-blue;
$stepper-font-size: 0.77rem;

.section {
  display: block;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: $spacer-8;
  }
}

.tile-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$tile-margin;
  margin-right: -$tile-margin;
}

.tile-col {
  padding-left: $tile-margin;
  padding-right: $tile-margin;
}

.data-table {
  border: none;

  thead {
    border-top: $data-table-border;
  }

  thead,
  tr {
    border-bottom: $data-table-border;
  }

  th,
  td {
    border: none !important;
    line-height: 1.25;
    height: 3.3rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
  &.with-outer-padding {
    th,
    td {
      &:first-child {
        padding-left: $spacer;
      }

      &:last-child {
        padding-right: $spacer;
      }
    }
  }

  tbody th {
    font-weight: $font-weight-normal;
  }

  thead,
  tbody {
    th,
    td {
      &:not(:last-child) {
        border-right: $data-table-border !important;
      }
    }
  }

  tfoot {
    tr {
      border-bottom: none;
    }
    th,
    td {
      &:not(.normal-cell) {
        font-size: $h3-font-size;
        color: $green-200;
      }
    }
  }
}

kpt-tip {
  margin: $spacer-5 0 $spacer-7 0 !important;
}

kpt-tile .graph {
  padding: 0;
}

.graph-small-square {
  width: 11.538rem;
  height: 11.538rem;
}

.graph-medium-square {
  width: 13.538rem;
  height: 13.538rem;
}

.graph-xsmall-rectangle {
  width: 100%;
  height: $spacer-9;
}

.graph-small-rectangle {
  width: 100%;
  height: 7.692rem;
}

.graph-medium-rectangle {
  width: 100%;
  height: 13.538rem;
}

.graph-large-rectangle {
  width: 100%;
  height: 30rem;
}

.financial-plan-stepper {
  .step-label {
    font-size: $stepper-font-size;
  }

  .stepper-body {
    padding: 0.1rem !important;
  }
}
.financial-plan-tabs {
  .nav-item .nav-link {
    font-size: $stepper-font-size;
  }
}

kpt-introduction {
  .body {
    padding: $spacer-3 $sheet-box-padding 0 !important;
  }
}

.avoid-page-break {
  page-break-inside: avoid;
}

@media print {
  kpt-introduction {
    .body {
      padding: 0 $sheet-box-padding !important;
    }
  }
}

.skip-sum {
  display: inline-flex;
  &:before {
    content: '(';
  }
  &:after {
    content: ')';
  }
}
