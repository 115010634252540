/**
 *	About consultant
 */

.about-consultant {
  &__header {
    color: $white;
    text-align: center;
    padding: ($spacer * 2) 0;
  }

  &__name-edit {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__name {
    @include smart-short-text;
  }

  & &__edit {
    margin-left: ($spacer * 3);
  }

  &__position {
    @include smart-short-text;
  }
}
