/*
 -  Core Settings
 -
 -  Helper file to load core functionality and settings for styles
 -  Only use for styling modular components in Angular
 */

@import '../01_base/variables'; // Variables
@import 'field.variables'; // Forms variables
@import '../01_base/bootstrap'; // Bootstrap functions & variables & mixins
@import 'node_modules/bootstrap/scss/root'; // Bootstrap functions
@import 'node_modules/bootstrap/scss/reboot'; // Bootstrap root
@import 'node_modules/bootstrap/scss/type'; // Bootstrap type
@import 'node_modules/bootstrap/scss/images'; // Bootstrap images
@import 'node_modules/bootstrap/scss/badge'; // Bootstrap badge
@import 'node_modules/bootstrap/scss/breadcrumb'; // Bootstrap breadcrumb
@import 'node_modules/bootstrap/scss/pagination'; // Bootstrap pagination
@import 'node_modules/bootstrap/scss/code'; // Bootstrap code
@import 'node_modules/bootstrap/scss/grid'; // Bootstrap grid
@import 'node_modules/bootstrap/scss/transitions'; // Bootstrap transitions
@import 'node_modules/bootstrap/scss/utilities'; // Bootstrap utilities
@import 'node_modules/bootstrap/scss/tooltip'; // Bootstrap tooltip
@import '../01_base/mixins'; // Mixins
@import '../01_base/mixins.mb-global-indentation'; // Mixins mb-global-indentation
@import '../01_base/mixins.text-truncate'; // Mixins text-truncate
@import '../01_base/master'; // Master styles
@import '../01_base/display'; // Extended Bootstrap display utilities
@import '../01_base/utilities'; // Utilities
@import '../01_base/icons'; // Icons
@import '../01_base/typography'; // Typography
