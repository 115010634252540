/*
 -  Theme icons
 */

$icon-version: random();

@font-face {
  font-family: 'icons';
  src: url('#{$icon-path}/icons.ttf?#{$icon-version}') format('truetype'),
    url('#{$icon-path}/icons.woff?#{$icon-version}') format('woff'),
    url('#{$icon-path}/icons.svg?#{$icon-version}#icons') format('svg');
  font-weight: 400;
  font-style: normal;
}

.icon {
  @include icon;

  &--small {
    font-size: $icon-size-small;
  }

  &--half {
    font-size: $icon-size-half;
  }

  &--large {
    font-size: $icon-size-large;
  }

  &--superlarge {
    font-size: $icon-size-superlarge;
  }

  &--menu-item {
    font-size: $icon-size-menu-icon;
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &--wider-click-area {
    padding: ($spacer * 2);
    margin: (-$spacer * 2);
  }

  &__link {
    @include link-without-href {
      text-decoration: none;
    }
    @include link-without-href-interactive-self {
      color: $green-200;
    }
  }

  /*
	&__holder-small {
		position: relative;
		display: inline-block;
		width: 1.11rem;
		height: 1.11rem;
		vertical-align: middle;

		.icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	*/
}

@function unicode($str) {
  @return unquote('"') + $str + unquote('"');
}

// Icons variables array
$icons: (
  'add': \e900,
  'article': \e906,
  'close': \e901,
  'check': \e907,
  'menu': \e902,
  'component-low': \e903,
  'component-middle': \e904,
  'component-high': \e905,
  'plus': \e908,
  'edit': \e909,
  'bulb': \e90a,
  'arrow-left': \e90c,
  'arrow-right': \e90d,
  'arrow-down': \e90e,
  'arrow-up': \e90f,
  'bin': \e910,
  'minus': \e911,
  'arrow-link': \ea3c,
  'reality': \e912,
  'pension': \e913,
  'nonlife-insurance': \e914,
  'life-intime': \e915,
  'life-insurance': \e916,
  'leasing': \e917,
  'investment': \e918,
  'housing': \e919,
  'financial-plan': \e91a,
  'financial-analyse': \e91b,
  'energy': \e91c,
  'disposal': \e91d,
  'assets-liabilities': \e91e,
  'file': \e91f,
  'question-mark': \e920,
  'pin': \e921,
  'phone': \e922,
  'globe': \e923,
  'mail': \e924,
  'person': \e925,
  'list': \e926,
  'reserves': \e927,
  'risks': \e928,
  'eye': \e929,
  'refresh': \e92a,
  'gold': \e92b,
  'switch': \e950,
  'print': \e92c,
  'bulb-on': \e92d,
  'calendar': \e92e,
  'online': \e92f,
  'offline': \e930,
  'recommended': \e931,
  'external-link': \e932,
  'read-more': \e90b,
  'read-less': \e933,
  'close-modal': \e934,
  'reload': \e935,
  'confirm': \e93a,
  'confirm-edit': \e938,
  'refuse-changes': \e937,
  'arrow-up2': \e936,
  'pin2': \e939,
  'phone2': \e944,
  'gold2': \e93e,
  'email': \e93d,
  'life-insurance2': \e948,
  'whiteboard': \e947,
  'simplified-fa': \e946,
  'property': \e945,
  'plan-meeting': \e943,
  'person2': \e942,
  'loan': \e941,
  'housing2': \e940,
  'handle-money': \e93f,
  'car': \e93c,
  'calculator': \e93b,
  'warning': \e949,
  'erecord': \e94b,
  'krystufek': \e94a,
  'arrow-down-left': \e94c,
  'arrow-up-right': \e94d
);

// Icons application to class
@each $name, $icon in $icons {
  .icon--#{$name}::before {
    content: unicode($icon);
  }
}
