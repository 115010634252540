/*
 -  Master styles
 -
 -  This file defines html elements styles.
 */

@use 'sass:math';

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  font-size: $font-size-root-tablet;

  // Media queries
  @include media-breakpoint-up(xl) {
    font-size: $font-size-root-desktop;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  strong {
    font-weight: $font-weight-semibold;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  & > span {
    color: $text-muted;
    padding-left: math.div($spacer, 3);
    text-transform: none;
  }
}

h5,
h6,
.h5,
.h6 {
  color: $gray-400;
  font-weight: 700;
  margin-bottom: ($headings-margin-bottom * 0.5);
}

h1,
.h1 {
  font-weight: 300;
  line-height: $h1-font-line-height;
  color: $h1-font-color;
}

h2,
.h2 {
  line-height: $h2-font-line-height;
  color: $h2-font-color;
}

h3,
.h3 {
  line-height: $h3-font-line-height;
  color: $h3-font-color;
}

h4,
.h4 {
  line-height: $h4-font-line-height;
  color: $h4-font-color;
}

blockquote,
.blockquote {
  font-size: $blockquote-font-size;
  margin-bottom: $spacer;
}

.blockquote-footer {
  display: block;
  font-size: 81.25%; // back to default font-size
  color: $blockquote-small-color;

  &::before {
    content: '\2014 \00A0'; // em dash, nbsp
  }
}

.buttons-holder {
  > :not(:last-child) {
    margin-bottom: ($spacer * 0.5);
    margin-right: 0;

    // Media queries
    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
      margin-right: math.div($spacer, 3);
    }
  }

  > :not(:first-child) {
    margin-top: ($spacer * 0.5);
    margin-left: 0;

    // Media queries
    @include media-breakpoint-up(sm) {
      margin-top: 0;
      margin-left: math.div($spacer, 3);
    }
  }
}

// Override default Reboot style from Bootstrap FW
a {
  @include link-without-href {
    cursor: pointer;
    color: $link-color;
    text-decoration: $link-decoration;
  }

  @include link-without-href-interactive-self {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    outline: none;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

.focus-catcher {
  visibility: hidden;
  position: absolute;
  z-index: -1;
}
