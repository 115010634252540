@use '@angular/material' as mat;

@include mat.core();


$my-primary: mat.define-palette(mat.$indigo-palette, 500);

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

// generated with https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
$my-accent-palette: (
  50: #e1f1ef,
  100: #b6dbd6,
  200: #89c5bb,
  300: #60aea1,
  400: #489d8e,
  500: #368c7c,
  600: #328070,
  700: #2d7061,
  800: #276053,
  900: #1d4539,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  )
);
$my-accent: mat.define-palette($my-accent-palette);

$my-typography: mat.define-typography-config(
  $font-family: 'inherit',
);

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
  ),
  typography: $my-typography,
));

@include mat.all-component-themes($my-theme);

// make sure overlays are visible
.cdk-overlay-container {
  z-index: 10000;
}
