/*
 -  Table
 -
 -
 */

.border-left-unset {
  border-left: unset !important;
}

.border-right-unset {
  border-right: unset !important;
}

.border-top-unset {
  border-top: unset !important;
}

.border-bottom-unset {
  border-bottom: unset !important;
}

.border-unset {
  border: unset !important;
}
