/*
 -  Control
 -
 -
 */

@use 'sass:math';

.control {
  @include link-without-href-self {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 1;
    width: $control-size;
    height: $control-size;
    color: $blue;
    border: 2px solid $blue;
    border-radius: 50%;
    background-color: $white;
    text-decoration: none;
    text-align: center;
    @include transition(all ease-in-out $animation-speed);
  }

  @include link-without-href-interactive-self {
    background-color: $green-200;
    color: $white;
    border-color: $green-200;

    .control__label {
      color: $green-200;
    }
  }

  &--with-label {
    position: relative;
    margin-bottom: ($spacer * 2);
  }

  &:nth-child(n + 2) {
    margin-left: $control-margin-left-tablet;

    // Media queries
    @include media-breakpoint-up(xl) {
      margin-left: $control-margin-left-desktop;
    }
  }

  &__label {
    position: absolute;
    top: 100%;
    width: ($control-size + $control-label-width-overlapping-desktop);
    padding-top: ($spacer * 0.25);
    font-size: math.div(12rem, 18);
    font-weight: 600;
    text-transform: uppercase;
    @include smart-short-text;
    text-align: center;

    // Media queries
    @include media-breakpoint-up(xl) {
      width: ($control-size + $control-label-width-overlapping-desktop);
    }

    &.menu {
      top: auto;
      bottom: -0.8rem;
    }
  }

  &--border-none {
    @include link-without-href-self {
      border: none;
    }
  }
}
