/*
 -  Static pages
 -
 -
 */

@use 'sass:math';

.static-pages {
  background-color: $white;
  padding: ($spacer * 3);
  margin-bottom: $spacer;
  border-bottom: 4px solid $green-200;

  &:last-child {
    margin-bottom: 0;
  }
}

.static-page-holder {
  background-color: $body-bg;
  margin: -($spacer * 0.5);
  padding: $spacer 0;

  // Media queries
  @include media-breakpoint-up(md) {
    margin: -$spacer;
  }
}

.static-page-settings {
  .tabs .tabs {
    margin-bottom: 0;
  }

  .tab-content .tab-content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

.static-context {
  .icon-boxes {
    margin: (-$spacer * 0.5) (-$spacer * 0.5) $spacer (-$spacer * 0.5);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .icon-box {
    width: 8.33rem;
    // height: 5.33rem;
    border: 1px solid $gray-100;
    border-radius: $btn-border-radius;
    padding: math.div($spacer, 1.2) math.div($spacer, 6) ($spacer * 0.5) math.div($spacer, 6);
    margin: ($spacer * 0.25) ($spacer * 0.5);
    text-align: center;

    &__symbol {
      display: block;
      margin-bottom: math.div($spacer, 3);
      color: $green-300;
    }

    &__name {
      display: block;
      @include smart-short-text;
      font-size: $small-font-size;
    }
  }

  .colors-overview {
    margin: (-$spacer * 0.25) (-$spacer * 0.5) $spacer (-$spacer * 0.5);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .color {
    position: relative;
    display: flex;
    width: 6.67rem;
    height: 6.67rem;
    border-radius: 100%;
    margin: ($spacer * 0.25) ($spacer * 0.5);
    cursor: help;

    &--with-border {
      border: 1px solid $gray-050;
    }

    &__name,
    &__value {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      padding: ($spacer * 0.25) ($spacer * 0.5);
      text-align: center;
      color: $white;
      font-size: $small-font-size;
      width: 86%;
      font-weight: 600;

      &--color-dark {
        color: $font-text;
      }
    }

    &__value {
      display: none;
    }

    &:hover,
    &:focus {
      .color__name {
        display: none;
      }

      .color__value {
        display: block;
      }
    }
  }

  .btn-holder {
    kpt-button {
      margin-bottom: $spacer;

      // Media queries
      @include media-breakpoint-up(sm) {
        margin-right: $spacer;
      }

      &:last-of-type {
        // Media queries
        @include media-breakpoint-up(sm) {
          margin-right: 0;
        }
      }
    }
  }

  .navigation-main {
    @include reset-list;
    display: flex;
    margin-left: -($spacer * 0.5);
    margin-right: -($spacer * 0.5);
  }

  .navigation-main__item {
    width: math.div(100%, 3);
    margin-left: ($spacer * 0.5);
    margin-right: ($spacer * 0.5);
  }

  .navigation-main__link {
    @include link-without-href-self {
      display: block;
      border-radius: 0.44rem;
      padding: ($spacer) ($spacer * 2);
      background-color: $green-200;
      color: $white;
      text-decoration: none;
    }

    @include link-without-href-interactive-self {
      background-color: $green-300;
    }
  }

  .navigation-main-sub {
    @include reset-list;
    padding: ($spacer) ($spacer * 2);
    font-size: 0.9rem;
    display: flex;
    flex-wrap: wrap;
  }

  .navigation-main-sub__item {
    margin-right: ($spacer * 2);

    &--active {
      .navigation-main-sub__link {
        @include link-without-href-self {
          text-decoration: $link-hover-decoration;
          font-weight: 600;
        }
      }
    }
  }

  .bg--static-inverse {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -$spacer;
      left: $spacer;
      width: calc(100% - (2 * #{$spacer}));
      height: calc(100% + (2 * #{$spacer}));
      @include turquoise-dark-gradient;
    }
  }

  .breadcrumb-static {
    margin-top: ($spacer * 4);
    margin-bottom: $spacer;

    .breadcrumb__text {
      color: $white;
    }
  }
}
