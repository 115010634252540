/*
 -  Main
 -
 -
 */

.main {
  position: relative;

  ol,
  ul {
    @include reset-list;
    margin-bottom: $lists-margin-bottom;

    > li {
      position: relative;
      padding-left: $lists-padding-left;
      padding-bottom: ($lists-margin-bottom * 0.5);

      &:last-child {
        padding-bottom: 0;
      }
    }

    ol,
    ul {
      margin-bottom: 0;
      padding-left: $lists-padding-left;
      padding-bottom: ($lists-margin-bottom * 0.5);

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  ul {
    > li {
      &::before {
        content: '';
        position: absolute;
        top: ($spacer * 1.32);
        left: ($list-bullet-size * 0.5);
        width: $list-bullet-size;
        height: $list-bullet-size;
        border-radius: 100%;
        background-color: $font-text;
      }
    }

    &.no-bullets {
      > li {
        &::before {
          display: none;
        }
      }
    }
  }

  ol {
    counter-reset: ol-list-1;

    > li {
      counter-increment: ol-list-1;
    }

    > li::before {
      content: counter(ol-list-1, decimal) '.';
      position: absolute;
      top: 0.28rem;
      left: ($list-bullet-size * 0.5);
      color: $font-text;
      font-weight: 700;
      font-size: $small-font-size;
    }

    ol {
      counter-reset: ol-list-2;

      > li {
        counter-increment: ol-list-2;
      }

      > li::before {
        content: counter(ol-list-2, lower-latin) ')';
      }

      ol {
        counter-reset: ol-list-3;

        > li {
          counter-increment: ol-list-3;
        }

        > li::before {
          content: counter(ol-list-3, lower-roman) '.';
        }
      }
    }
  }

  .check-list {
    li {
      padding-bottom: ($spacer * 3.25);
      padding-left: ($check-list-bullet-size + ($spacer * 2));

      &::before {
        content: '\e907';
        @include icon;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -0.06rem;
        left: 0;
        width: $check-list-bullet-size;
        height: $check-list-bullet-size;
        background-color: rgba($green-100, 0.3);
        color: $green-200;
        border-radius: 50%;
      }
    }
  }

  .split-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      width: 50%;
    }
  }
}
