@import 'src/assets/scss/01_base/variables';
@import 'src/shared/ui/button/button.component.variables';

.onboarding {
  &-highlighter.introjs-helperLayer {
    box-shadow: rgba(21, 41, 51, 0.8) 0 0 0 5000px !important;
    border: 3px solid $primary;
    border-radius: 10px;
  }

  &-tooltip {
    &.introjs-tooltip {
      border-radius: 0;

      min-width: 560px;
      max-width: 580px;

      padding: 18px 24px 32px;
      border-color: $primary;

      &.introjs-bottom,
      &.introjs-bottom-left-aligned,
      &.introjs-bottom-middle-aligned,
      &.introjs-bottom-right-aligned {
        border-top-width: 3px;
        border-top-style: solid;
        transform: translate3d(0, 14px, 0);
      }

      &.introjs-top,
      &.introjs-top-left-aligned,
      &.introjs-top-middle-aligned,
      &.introjs-top-right-aligned {
        border-bottom-width: 3px;
        border-bottom-style: solid;
        transform: translate3d(0, -14px, 0);
      }

      &.introjs-right,
      &.introjs-right-top-aligned,
      &.introjs-right-middle-aligned,
      &.introjs-right-bototm-aligned {
        border-left-width: 3px;
        border-left-style: solid;
        transform: translate3d(14px, 0, 0);
      }

      &.introjs-left,
      &.introjs-left-top-aligned,
      &.introjs-left-middle-aligned,
      &.introjs-left-bototm-aligned {
        border-right-width: 3px;
        border-right-style: solid;
        transform: translate3d(-14px, 0, 0);
      }

      &.introjs-floating {
        border-top-width: 3px;
        border-top-style: solid;
      }

      @media only screen and (max-width: 567px) {
        min-width: 300px;
        max-width: 330px;
      }

      .introjs-arrow {
        border: 10px solid transparent;

        &.top,
        &.top-middle,
        &.top-right {
          border-bottom-color: $primary;
          top: -22px;
        }

        &.bottom,
        &.bottom-middle,
        &.bottom-right {
          border-top-color: $primary;
          bottom: -22px;
        }

        &.left,
        &.left-middle,
        &.left-bottom {
          border-right-color: $primary;
          left: -22px;
        }

        &.right,
        &.right-middle,
        &.right-bottom {
          border-left-color: $primary;
          right: -22px;
        }
      }

      .introjs-tooltip-header {
        padding: 0;

        .introjs-tooltip-title {
          font-size: $h3-font-size !important;
          line-height: $h3-font-line-height !important;
          text-transform: none;

          font-weight: $font-weight-semibold !important;
        }

        .introjs-skipbutton {
          text-decoration: none;
        }
      }

      .introjs-tooltiptext {
        padding: 2.1rem 0;
      }

      .introjs-bullets {
        padding-bottom: 2.6rem;

        border-bottom-color: $gray-100;

        ul {
          li a {
            background-color: $gray-100;

            width: 8px;
            height: 8px;

            &.active {
              background-color: $blue;

              width: 16px;
            }
          }
        }
      }

      .introjs-tooltipbuttons {
        padding: 2.1rem 0 0;

        display: flex;
        justify-content: center;

        gap: 1rem;

        @media only screen and (max-width: 567px) {
          flex-wrap: wrap;
        }

        .introjs-button {
          display: inline-block;
          font-weight: $btn-font-weight;
          text-align: center;
          white-space: nowrap;
          vertical-align: middle;
          user-select: none;
          padding: $btn-padding-y $btn-padding-x;
          font-size: 1rem;
          line-height: $btn-line-height;
          border-radius: $btn-border-radius;
          transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          position: relative;
          letter-spacing: $btn-font-spacing;
          text-transform: uppercase;
          text-decoration: none;
          box-shadow: none;
          border: none;
          cursor: pointer;
          min-height: inherit;

          &.introjs-disabled {
            display: none;

            & + .introjs-button {
              margin-right: auto;
              margin-left: auto;
              @media only screen and (max-width: 567px) {
                margin-top: 0;
              }
            }
          }

          &.introjs-prevbutton {
            color: $primary;
            background-color: transparent;
            background-image: none;
            border-color: $primary;
            box-shadow: inset 0 0 0 2px;
            &:hover,
            &:active {
              background: $btn-outline-bg-hover;
            }
          }

          &.introjs-nextbutton {
            color: $white;
            background-color: $primary;
            border-color: $primary;
            box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%), 0 1px 1px rgb(0 0 0 / 8%);
            &:hover,
            &:active {
              background-color: $primary-dark;
            }
          }

          // Resetting Intro.js styles
          float: none;
          text-shadow: none;
        }

        &::after {
          content: none;
        }
      }
    }

    &--final.introjs-tooltip {
      max-width: 620px;

      .introjs-tooltiptext {
        max-width: 400px;
        margin-right: auto;
        margin-left: auto;
      }
    }

    p {
      font-family: 'worksans', sans-serif;
      margin-bottom: 0.8rem;
    }
  }
}
