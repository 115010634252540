/*
 -  Theme typography
 -
 -  This file contains @font-face declarations.
 */

/*
 -	Open Sans
 */

// Font weight: Light
@include font-face('worksans', '../fonts/worksans/worksans-light', 300);

// Font weight: Regular
@include font-face('worksans', '../fonts/worksans/worksans-regular', 400);

// Font weight: Regular
@include font-face('worksans', '../fonts/worksans/worksans-medium', 500);

// Font weight: Semi bold
@include font-face('worksans', '../fonts/worksans/worksans-semibold', 600);

// Font weight: Bold
@include font-face('worksans', '../fonts/worksans/worksans-bold', 700);

// Font weight: Extra Bold
// @include font-face('worksans', '../fonts/worksans/worksans-extrabold', 800);

/*
 -	Roboto
 */

// Font weight: Light
@include font-face('Roboto', '../fonts/Roboto/Roboto-Light', 300);

// Font weight: Regular
@include font-face('Roboto', '../fonts/Roboto/Roboto-Regular', 400);

// Font weight: Semi bold
@include font-face('Roboto', '../fonts/Roboto/Roboto-Medium', 600);

// Font weight: Bold
@include font-face('Roboto', '../fonts/Roboto/Roboto-Bold', 700);
