/* You can add global styles to this file, and also import other style files */
@import 'assets/scss/01_base/base';
@import 'app/modules/life-insurance-old/1-insurance-protection/family-form/form';
@import 'assets/scss/01_base/field.variables';
@import 'assets/scss/02_layout/print';
@import 'assets/scss/material';

body {
  font-family: sans-serif;
}

.interactive {
  cursor: pointer;
  outline: none;
}

.columns {
  display: flex;
  flex-direction: row;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dark-backdrop {
    background: #000;
    opacity: 0.75 !important;
  }
}

table {
  border-collapse: collapse;
}

%input-no-stepper {
  & input[type='number']::-webkit-outer-spin-button,
  & input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type='number'] {
    -moz-appearance: textfield;
  }
}

.input-no-stepper {
  @extend %input-no-stepper;
}

.gray-field {
  %background {
    background: $light-blue !important;
  }

  @extend %background;
  @extend %input-no-stepper;

  .input-group-append {
    @extend %background;

    &::after {
      @extend %background;
    }
  }
}

.label-more-space ~ label {
  padding-left: ($custom-control-indicator-size + map-get($spacers, 3)) !important;
}

.dark-tooltip {
  background-color: $blue;
  color: $green-100;
}

.warning-light {
  background-color: $promo-cheese;
  border-right: 1px solid $promo-cheese;
}

.danger-light {
  background-color: $promo-salmon;
  border-right: 1px solid $promo-salmon;
}

.text-danger {
  color: $promo-salmon;
}

// Keep height for empty option of ng-select
.ng-select .ng-option {
  min-height: calc(1.7em + #{map-get($spacers, 4)});
}

// generate 2D matrix of spacing classes for Flexbox wrappers RowComponent, ColComponent
@each $verticalKey, $verticalVal in $spacers {
  @each $horizontalKey, $horizontalVal in $spacers {
    .container-spacing-#{$verticalKey}-#{$horizontalKey} {
      margin: #{-$verticalVal} #{-$horizontalVal};
      padding: #{0.5 * $verticalVal} #{0.5 * $horizontalVal};

      > * {
        margin: #{0.5 * $verticalVal} #{0.5 * $horizontalVal};
      }
    }
  }
}
