@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

// asset-detail-modal depends on bootstrap styles like modal-open, but does not use the same service to open the modal
// as other modals. This results in a conflict where other modal removes the modal-open class on close, because it thinks there
// are no other modals open
.asset-detail-modal-open {
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}
