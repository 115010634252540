.data-table {
  width: 100%;
  background: white;
  border: 1px solid $light-blue;

  th,
  tfoot td {
    &:not(.normal-cell) {
      font-weight: 600;
    }
  }

  tfoot td {
    border-left: unset;
    border-right: unset;
    border-top-width: 4px;

    &:last-child {
      text-align: right;
    }
  }

  td,
  th {
    padding: map-get($spacers, 3) map-get($spacers, 4);
    border: 1px solid $light-blue;
  }

  th {
    border-left: unset;
    border-right: unset;
  }

  tr.spacer {
    height: map-get($spacers, 6);
  }
}
