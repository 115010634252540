/*
 -  Variables
 -
 -
 */

// Colors pallete

@use 'sass:math';

$green-selection: #f2f8f6;
$green-025: #f3f7f8;
$green-050: #e5f1ee;
$green-100: #bedbd4;
$green-200: #60aea1;
$green-300: #006677;
$green-400: #1c606e;
$blue: #2a4b5c;
$sky-gray: #152933;
$titanium-gray: #78858e;
$bright-blue: #3f8efc;
$night-blue: #abb4bb;
$light-blue: #e8eff2;
$sky-blue: #a1d6ca;
$orange: #d23a00;
$red: #dc3545;

$promo-cheese: #fedc98;
$promo-salmon: #ec7978;
$promo-tomato: #d00036;
$promo-avocado: #00965e;
$promo-champagne: #ffc95e;

$light-blue-500: #f4f7f9; // Light Blue level 05
$light-blue-600: #f1f6f7; // Light Blue level 06

$gray-000: #fff; // Gray level 00
$gray-050: #f6f6f6; // Gray level 01
$gray-100: #e6e6e6; // Gray level 01
$gray-050: $gray-100; // Gray level 00
$gray-200: #999; // Gray level 02
$gray-150: $gray-200; // Gray level 01
$gray-300: #666; // Gray level 03
$gray-400: #403c39; // Gray level 04
$gray-500: $gray-400; // Gray level 04
$gray-600: $gray-400; // Gray level 04
$gray-1000: #000; // Gray level 10
$white: $gray-000;
$black: $gray-1000;

$font-header: $gray-500;
$font-text: $blue;
$font-secondary: #919191;
$font-link: $green-100;

$text-muted: $font-secondary;

$primary: $green-200; // Primary btn
$primary-dark: darken($primary, 8%); // Primary btn hover
$secondary: $green-300; // Secondary btn
$secondary-dark: darken($secondary, 10%); // Secondary btn hover

$success: $promo-avocado; // Success btn
$success-lighten: lighten($success, 10%); // Success bg
$success-dark: darken($success, 15%); // Success btn hover
$success-darken: darken($success, 25%); // Success text
$warning: $promo-champagne; // Warning btn
$warning-lighten: lighten($warning, 10%); // Warning bg
$warning-dark: darken($warning, 15%); // Warning btn hover
$warning-darken: darken($warning, 25%); // Warning text
$danger: $promo-tomato; // Danger btn
$danger-lighten: lighten($danger, 10%); // Danger bg
$danger-dark: darken($danger, 10%); // Danger btn hover
$danger-darken: darken($danger, 20%); // Danger text
$info: $blue;

$light: $gray-300; // Light btn
$light-dark: $gray-400; // Light btn hover
$light-darken: $gray-500; // Light btn active
$disabled: #a5bbc5; // Disabled color

$gradient-green-light: $primary; // Gradient green light
$gradient-green-dark: $primary-dark; // Gradient green dark
$gradient-blue-light: $secondary; // Gradient blue light
$gradient-blue-dark: $secondary-dark; // Gradient blue dark, cold
$gradient-orange-light: $warning; // Gradient orange light, normal
$gradient-orange-dark: $warning-dark; // Gradient orange dark, normal
$gradient-red-light: $danger; // Gradient red light, warm
$gradient-red-dark: $danger-dark; // Gradient red dark, warm

$icons-primary: $green-400;
$icons-secondary: $white;
$icons-active: $primary;
$icons-inactive: $gray-200;
$icons-hover: $gray-300;

$body-bg: $gray-050;
$body-color: $font-text;

$sheet-box-color: $white;

$alert-info-color: rgba($green-100, 0.9);
$alert-danger-color: rgba($danger, 0.9);
$alert-success-color: rgba($success, 0.9);
$alert-warning-color: rgba($warning, 0.7);

$theme-colors: (
  'primary': $primary,
  'primary-dark': $primary-dark,
  'secondary': $secondary,
  'secondary-dark': $secondary-dark,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'promo-salmon': $promo-salmon,
  'promo-cheese': $promo-cheese,
  'gray-100': $gray-100,
  'gray-200': $gray-200
);

// Config global
$enable-shadows: true;
$animation-speed: 150ms;
$animation-easing: ease-in-out;
$animation-easing-bezier: cubic-bezier(0, 0, 0.2, 1);
$enable-transitions: true;

// TODO? chne: to rem
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px
) !default;

$grid-columns: 12;
$grid-gutter-width: 0.555rem; // 10px in InVision
$spacer: $grid-gutter-width;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 2
    ),
    5: (
      $spacer * 3
    ),
    6: (
      $spacer * 4
    ),
    7: (
      $spacer * 6
    ),
    8: (
      $spacer * 8
    ),
    9: (
      $spacer * 10
    ),
    10: (
      $spacer * 12
    )
  ),
  $spacers
);

$spacer-1: map-get($spacers, 1);
$spacer-2: map-get($spacers, 2);
$spacer-3: map-get($spacers, 3);
$spacer-4: map-get($spacers, 4);
$spacer-5: map-get($spacers, 5);
$spacer-6: map-get($spacers, 6);
$spacer-7: map-get($spacers, 7);
$spacer-8: map-get($spacers, 8);
$spacer-9: map-get($spacers, 9);
$spacer-10: map-get($spacers, 10);

$sizes: () !default;
$sizes: map-merge(
  (
    10: 10%,
    15: 15%,
    20: 20%,
    25: 25%,
    28: 28%,
    30: 30%,
    34: 34%,
    40: 40%,
    50: 50%,
    60: 60%,
    70: 70%,
    75: 75%,
    80: 80%,
    90: 90%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

$body-min-width: 320px;
$body-max-width: 1280px;

$mb-global-indentation-tablet: ($spacer * 6);
$mb-global-indentation: ($spacer * 7);

// Typography
$font-family-sans-serif: 'worksans', 'Helvetica Neue', arial;
$font-family-roboto: 'Roboto', 'monospace';
$font-size-base: 1rem;
$font-size-root-tablet: 13px;
$font-size-root-desktop: 15px;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$line-height-base: math.div(32, 18);

$h1-font-size: math.div(48rem, 18);
$h1-font-line-height: math.div(56, 48);
$h1-font-color: $blue;

$h2-font-size: math.div(30rem, 18);
$h2-font-line-height: math.div(44, 30);
$h2-font-color: $blue;

$h3-font-size: math.div(24rem, 18);
$h3-font-line-height: math.div(40, 24);
$h3-font-color: $blue;

$h4-font-size: math.div(18rem, 18);
$h4-font-line-height: math.div(32, 18);
$h4-font-color: $blue;

$h5-font-size: math.div(16rem, 18);
$h6-font-size: math.div(16rem, 18);

$headings-color: $font-header;
$headings-font-weight: 400;
$headings-margin-bottom: ($spacer * 3);
$paragraph-margin-bottom: ($spacer * 3);
$lists-margin-bottom: ($spacer * 3);
$lists-padding-left: ($spacer * 3);

$smaller-font-size: math.div(13rem, 15);
$small-font-size: math.div(14rem, 18);
$lead-font-weight: 400;

$blockquote-small-color: $gray-300 !default;
$blockquote-font-size: ($font-size-base * 1.231) !default;

$list-bullet-size: 0.4rem;

$check-list-bullet-size: 1.89rem;

$border-color: $gray-100;
$border-width: 2px;

// Links
$link-color: $blue;
$link-decoration: underline;
$link-hover-color: $link-color;
$link-hover-decoration: none;
$link-external-icon-size: 0.72rem;

// Icons
$icon-path: '../../fonts/icons';
$icon-sizes: (
  small: math.div(14rem, 18),
  normal: math.div(16rem, 18),
  half: math.div(8rem, 18),
  large: math.div(20rem, 18),
  larger: math.div(30rem, 18),
  superlarge: math.div(40rem, 18)
);
$icon-size-small: map-get($icon-sizes, small);
$icon-size: map-get($icon-sizes, normal);
$icon-size-half: map-get($icon-sizes, half);
$icon-size-large: map-get($icon-sizes, large);
$icon-size-larger: map-get($icon-sizes, larger);
$icon-size-superlarge: map-get($icon-sizes, superlarge);

$small-font-size: math.div(14rem, 18);
$line-height-sm: math.div(20, 14);

// Layout

// Header
$header-bg: $white;
$header-offset-tablet: 0.875rem;
$header-height-tablet: 3rem + ($header-offset-tablet * 2);
$header-offset-desktop: 1.11rem;
$header-height-desktop: 3.11rem + ($header-offset-desktop * 2);

// Progress / stepper common
$progress-height: 4.44rem;
$progress-bullet-size: 2rem;

// Logo
$logo-width: 6.94rem;
$logo-height: auto;
$logo-offset-left: 3.06rem;
$logo-text-size: 0.8125rem;

// Menu
$menu-toggle-size: $icon-size;

// Footer
$footer-bg: $white;
$footer-height: 4.44rem;
$scroll-top-size: $spacer;

// Sidebar
$aside-bg: $gray-500;
$aside-bg-secondary: $gray-600;
$aside-width: 15rem;

// Badges
$badge-font-size: 0.75rem;
$badge-font-weight: 400;
$badge-padding-y: ($spacer * 0.25);
$badge-padding-x: ($spacer * 0.5);

// Dropdown
$dropdown-padding-y: 0;
$dropdown-border-radius: 0;
$dropdown-item-min-height: 2.22rem;
$dropdown-item-padding-y: math.div($spacer, 3.75);
$dropdown-item-padding-x: ($spacer * 0.5);
$dropdown-link-color: $gray-400;
$dropdown-box-shadow: none;
$dropdown-link-hover-color: $gray-400;
$dropdown-link-hover-bg: $gray-100;
$dropdown-link-active-color: $gray-400;
$dropdown-link-active-bg: $gray-100;
$dropdown-beak-border-width: 0.83rem;
$dropdown-beak-border-style: solid;
$dropdown-beak-border-color: $white;
$dropdown-beak-box-shadow: none;
$dropdown-menu-offset: ($dropdown-beak-border-width - 0.17rem);

// Tabs
$nav-tabs-border-width: 0;
$nav-tabs-border-radius: 0;
$nav-link-disabled-color: rgba($gray-300, 0.5);
$nav-tabs-link-active-color: $gray-400;
$nav-tabs-link-active-bg: $white;
$nav-tabs-item-min-width: 6.67rem;
$nav-tabs-item-height: 2.22rem;
$nav-tabs-item-more-width: 2.22rem;

// Close
$close-font-size: $icon-size;
$close-font-weight: 400;
$close-color: $blue;
$close-text-shadow: none;

// Banner
$banner-bg: $gray-100;
$banner-content-max-width-xs: 16.25rem;
$banner-content-max-width-md: 18.89rem;

// Z-indexes
$zindex-alert: 1000;
$zindex-body-overlayer: 2000;
$zindex-body-overlayer-full: 3000;
$zindex-body-overlayer-visible: 3010;
$zindex-modal-backdrop: 3000;
$zindex-modal: 3010;
$zindex-card-holder-without-control: 1;
$zindex-datepicker-content: 3020;
$zindex-dropdown-menu: 3001;
$zindex-dropdown-menu-input: 9;
$zindex-dropdown-menu-input-beak: -2;
$zindex-input-group-btn: 9;
$zindex-input-group-form-control: 2;
$zindex-placeloader-holder: 10;
$zindex-form-file-input: -1;
$zindex-graph-tabs-active-after: 99;
$zindex-tooltip-content: 10000;
$zindex-tooltip-beak: 10001;
$zindex-tooltip-graph-line: 10002;
$zindex-bubble-center: 1;
$zindex-ng-select-arrow-wrapper: 1;
$zindex-phase-item: 1;

// Breadcrumb
$breadcrumb-holder-height-xs: 3rem;
$breadcrumb-bg: transparent;
$breadcrumb-border-radius: 0;
$breadcrumb-item-padding: ($spacer * 0.5);
$breadcrumb-item-padding-md: math.div($spacer, 1.5);
$breadcrumb-divider: '\e911';
$breadcrumb-active-color: $gray-500;
$breadcrumb-back-width-xs: 2.5rem;

// Card
$card-bg: $white;
$card-border-width: 0;
$card-border-radius: 0;
$card-spacer-x: 0;
$card-spacer-y: $spacer;
$card-img-max-height: 9.33rem;
$card-img-border-width: 0.56rem;
$card-img-border-color: $gray-050;

$card-tile-height: 10rem;
$card-tile-img-max-height: 4.44rem;
$card-widget-img-max-width: 11.94rem;
$card-widget-img-small-max-width: 5rem;

$card-list-item-dot-size: 0.56rem;

// Pagination
$pagination-padding-y: 0;
$pagination-padding-x: 0;
$pagination-color: $gray-400;
$pagination-border-width: 0;
$pagination-link-width: 2.22rem;
$pagination-link-height: 2.22rem;
$pagination-link-line-height: $pagination-link-height;
$pagination-link-radius: 2px;

$pagination-hover-color: $primary-dark;
$pagination-hover-bg: $gray-100;
$pagination-focus-color: $pagination-hover-color;
$pagination-hover-decoration: underline;
$pagination-focus-box-shadow: none;
$pagination-active-bg-gradient: linear-gradient(
  90deg,
  $gradient-green-light,
  $gradient-green-light
);

// Placeloader
$placeloader-circle-color: $primary;
$placeloader-circle-size: 0.33rem;
$placeloader-circle-radius: 0;

// Indicators
$indicator-width: 0.57rem;
$indicator-height: 0.33rem;

// Tooltip
$tooltip-beak-border-width: 0.83rem;
$tooltip-beak-border-style: solid;
$tooltip-beak-shadow: -2px 2px 2px rgba($green-400, 0.1);
$tooltip-content-offset: math.div($spacer, 3) + ($tooltip-beak-border-width - 0.17rem);
$tooltip-content-border-radius: 4px;
$tooltip-content-box-shadow: 0 0 4px rgba($green-400, 0.22);

// Control
$control-size: 2.22rem;
$control-margin-left-tablet: ($spacer * 4.25);
$control-margin-left-desktop: ($spacer * 4.5);
$control-label-width-overlapping-tablet: ($control-margin-left-tablet - $spacer);
$control-label-width-overlapping-desktop: ($control-margin-left-desktop - $spacer);

// Segment
$segment-assets-liabilities-min-height: 16.11rem;
$segment-assets-liabilities-height: 100%;
$segment-margin: map-get($spacers, 4);
$segment-padding-vertical: map-get($spacers, 4);
$segment-padding-horizontal: map-get($spacers, 5);
$segment-radius: 0.44rem;
$segment-label-size: $h3-font-size;
$segment-min-height: 7.78rem;
$segment-margin: map-get($spacers, 4);
$segment-padding-vertical: map-get($spacers, 4);
$segment-padding-horizontal: map-get($spacers, 5);
$segment-radius: 0.44rem;
$segment-label-size: $h3-font-size;
$segment-box-color: #deede9;

// Segment item
$segment-item-bg-color: #f8fbfa;
$segment-item-padding-vertical: ($spacer * 1.5);
$segment-item-padding-horizontal: ($spacer * 2.75);
$segment-item-radius: 0.44rem;
$segment-item-margin: $spacer;

// Search / create client
$presentation-kapitol-logo-holder-size: 10rem;

// Sidebar menu
$icon-size-menu-icon: math.div(30rem, 18);

// Sheet-box
$sheet-box-padding: 5rem;
