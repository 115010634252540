@import '../01_base/variables';

@media print {
  html {
    font-size: $font-size-root-desktop;
  }

  body,
  .container-content {
    background: $white;
    margin: 0;
    padding: 0;

    &:before {
      background: $white !important;
    }
  }

  kpt-header,
  kpt-sidebar,
  kpt-stepper-progress,
  kpt-header-info-row,
  kpt-intro-js,
  kpt-navigation-arrows,
  #__ybug-launcher,
  .hide-on-print {
    display: none !important;
  }

  .container-content::before {
    height: unset !important;
  }

  kpt-content-box > .content-box {
    border: unset !important;
    padding: unset !important;
  }
}
