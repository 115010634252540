/*
 -  Button variables
 -
 -
 */

@use 'sass:math';

$btn-font-weight: 600;
$btn-font-spacing: 0.03rem;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: $btn-focus-box-shadow;
$btn-disabled-opacity: 1;
$btn-disabled-bg: #dfe8ec;
$btn-disabled-color: #a5bbc5;
$btn-link-disabled-color: $disabled;
$btn-width: 8.89rem;
$btn-width-large: 13.33rem;
$btn-height-small: 1.67rem;
$btn-height: 2.22rem;
$btn-icon-size-small: 1.67rem;
$btn-icon-size: 2.22rem;
$btn-padding-y-sm: ($spacer);
$btn-padding-y: ($spacer * 2);
$btn-padding-x-sm: ($spacer * 5);
$btn-padding-x: ($spacer * 4);
$btn-line-height-sm: 1.6;
$btn-line-height: 1.6;
$btn-border-radius: 2px;
$btn-border-radius-sm: $btn-border-radius;
$btn-outline-width: 2px;
$btn-outline-bg-hover: rgba(190, 219, 212, 0.3);
$font-size-sm: math.div(16rem, 18);
